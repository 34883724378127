<template>
  <div>
    <!--   <b-card no-body v-bind:class="[ (value <= 0) ? 'bg-danger' : 'bg-success']" style=" max-width: 18rem;min-height: 8rem;">-->
    <b-card
      no-body
      :class="backgroundColorClass"
      :style="{ width: width }"
      style="min-height: 8rem;"
    >
      <b-card-body class="p-2 d-flex justify-content-center align-items-center">
        <clip-loader
          v-if="loading"
          class="ml-3 mt-2"
          :size="40"
          color="#36D7B7"
          :loading="loading"
        />
        <div v-if="!this.loading">
          <p class="m-0" />
          <p class="m-0">
            {{ header }}
          </p>
          <p class="m-0">
            {{ subheader }}
          </p>
          <h1 class="m-0">
            {{ printValue }}
          </h1>
          <p class="m-0">
            {{ footer }}
          </p>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  components: {},
  // props: ['value', 'header', 'subheader', 'footer', 'postfix', 'prefix', 'width', 'hideSign', 'bgColorClass'],
  props: {
    value: {
      type: String,
      default: ""
    },
    header: {
      type: String,
      default: ""
    },
    subheader: {
      type: String,
      default: ""
    },
    footer: {
      type: String,
      default: ""
    },
    prefix: {
      type: String,
      default: ""
    },

    postfix: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: ""
    },
    hideSign: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    } /*
        bgColorClass: {
          type: String,
          default: ''
        },*/
  },
  data: function() {
    return {
      //  loading: false,
    };
  },
  computed: {
    sign() {
      if (this.hideSign || this.value === "") return "";

      return this.value > 0 ? "+" : "-";
    },
    absvalue() {
      return Math.abs(this.value);
    },
    backgroundColorClass() {
      if (this.value === "") return "bg-secondary";

      return parseFloat(this.value) <= 0 ? "bg-danger" : "bg-success";
    },
    formattedvalue() {
      return Math.round(this.absvalue).toLocaleString("en-US");
    },
    printValue() {
      return this.value !== ""
        ? this.sign + this.prefix + this.formattedvalue + this.postfix
        : "";
    }
  },
  mounted: function() {},

  methods: {},
  watch: {}
};
</script>

<style></style>
