var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    class: _vm.backgroundColorClass,
    staticStyle: {
      "min-height": "8rem"
    },
    style: {
      width: _vm.width
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "p-2 d-flex justify-content-center align-items-center"
  }, [_vm.loading ? _c('clip-loader', {
    staticClass: "ml-3 mt-2",
    attrs: {
      "size": 40,
      "color": "#36D7B7",
      "loading": _vm.loading
    }
  }) : _vm._e(), !this.loading ? _c('div', [_c('p', {
    staticClass: "m-0"
  }), _c('p', {
    staticClass: "m-0"
  }, [_vm._v(" " + _vm._s(_vm.header) + " ")]), _c('p', {
    staticClass: "m-0"
  }, [_vm._v(" " + _vm._s(_vm.subheader) + " ")]), _c('h1', {
    staticClass: "m-0"
  }, [_vm._v(" " + _vm._s(_vm.printValue) + " ")]), _c('p', {
    staticClass: "m-0"
  }, [_vm._v(" " + _vm._s(_vm.footer) + " ")])]) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }